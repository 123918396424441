html {
  height: 100vh;
  height: 100dvh;
}

body, #root {
  height: 100%;
}

body, textarea, button, input, select {
  font-family: "Inter variant0", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body, textarea {
  margin: 0;
}